.cardOpp {
  background: white;
  margin: 6px 12px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  box-shadow: 0px 0px 32px 0px rgba(180, 180, 180, 0.2);
}
.cardOpp:hover {
  cursor: pointer;
}

.cardOppHeader {
  display: flex;
  justify-content: space-between;
}
.cardOppHeader > div {
  width: 100%;
}
.cardTitle {
  color: #222;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.14px;
  word-wrap: break-word;
}
.cardName {
  color: #222;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.12px;
  word-wrap: break-word;
}
.cardDescription {
  color: #808080;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.12px;
  word-wrap: break-word;
}

.statusCard {
  width: fit-content;
  border-radius: 8px;
  font-family: Poppins;
  padding: 4px 8px;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}
.pendingCard {
  color: #4a1ff6;
  background: rgba(74, 31, 246, 0.2);
}
.negotiationCard {
  color: #f79009;
  background: rgba(247, 144, 9, 0.2);
}
.finishedCard {
  color: #12b76a;
  background: rgba(18, 183, 106, 0.2);
}
.canceledCard {
  color: #f04438;
  background: rgba(240, 68, 56, 0.2);
}
.cardCreators {
  display: flex;
  align-items: center;
}
.cardCreators > img {
  border-radius: 50%;
  margin-left: -12px;
  border: 4px solid white;
}

.cardCreators > img:first-child {
  margin-left: 0;
}
.cardFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardFooter > div > img {
  margin-right: 4px;
  cursor: pointer;
}
.cardDelete {
  position: absolute;
  display: flex;
  align-items: center;
  color: #ea2a2a;
  line-height: 150%;
  letter-spacing: -0.16px;
  padding: 8px 12px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #cbd2e0;
  background: #fff;
  transform: translateX(-80px);
  z-index: 10;
}
.cardDelete:hover {
  background: #eaedf2;
  cursor: pointer;
}
