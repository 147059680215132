.modalAddConversation {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.modalAddConversation__container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  max-width: 500px;
  padding: 32px;
  border-radius: 32px;
  background: #fff;
  box-shadow: 0px 0px 32px 0px rgba(180, 180, 180, 0.2);
}
.modalAddConversation__title {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 125%;
}
.modalAddConversation__subtitle {
  color: #222;
  text-align: left;
  line-height: 150%;
  letter-spacing: -0.16px;
}

.modalAddConversation__buttons {
  display: flex;
  gap: 16px;
  width: 100%;
}

.modalAddConversation__buttons > button {
  padding: 12px 16px;
  width: 100%;
  font-weight: 600;
  font-family: Poppins;
  font-size: 16px;
  border: none;
}
.sendMessage {
  border-radius: 8px;
  background: #7250f8;
  color: #fff;
}
.sendMessage:hover {
  cursor: pointer;
  background: #4a1ff6;
}
.modalAddConversation__buttons > button:last-child {
  border-radius: 8px;
  border: 2px solid #000;
  color: #000;
  background: none;
}
.modalAddConversation__buttons > button:last-child:hover {
  cursor: pointer;
  background: #cccccc;
}

.modalAddConversation__name {
  color: #7250f8;
}

.modalAddConversationMessage {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  height: 147px;
}
.modalAddConversationMessageTitle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modalAddConversationMessageTitle > label {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.14px;
}

.modalAddConversationMessageTitle > span {
  color: #cbd2e0;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.14px;
}

.modalAddConversationTextarea {
  width: calc(100% - 24px);
  font-family: Poppins;
  outline: none;
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #cbd2e0;
  background: #fff;
  resize: vertical;
}

.sendMessage__disabled {
  border-radius: 8px;
  background: #bfbfbf;
}
.sendMessage__disabled :hover {
  cursor: default;
  background: #bfbfbf;
}
