.chat__container {
  background: #eaedf2;
  height: calc(100vh - 136px);
  width: 100%;
  padding: 32px 32px 16px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}

.chat__messagesContainer {
  height: 100%;
  margin-bottom: 16px;
  overflow-y: auto;
}
.chat__inputContainer {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
}
.chat__inputContainer > input {
  width: 100%;
  display: flex;
  padding: 11px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #cbd2e0;
  background: #fff;
  outline: none;
  font-family: Poppins;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.16px;
}
.chat__inputContainer > input::placeholder {
  color: #cbd2e0;
}

.chat__inputContainer > button {
  display: flex;
  height: 46px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #7250f8;
  border: none;
  outline: none;
}
.chat__inputContainer > button:hover {
  cursor: pointer;
  background: #4a1ff6;
}
.chat__inputContainer > button > p {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.chat__inputContainer > button > img {
  width: 20px;
  height: 20px;
}

.chat__disabled > input {
  background: #bfbfbf;
}
.chat__disabled > input::placeholder {
  color: #808080;
}

.chat__disabled > button {
  background: #bfbfbf;
}
.chat__disabled > button:hover {
  cursor: default;
  background: #bfbfbf;
}
.chat__disabled > button > p {
  color: #808080;
}
.chat__disabled > button > img {
  filter: invert(50%);
}
