.emailVForm {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 450px;
  @media (max-width: 1024px) {
    width: 100%;
    
  padding: 16px;
  }
}

.emailVFormCard {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.emailVFormTitle {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 125%;
  margin: 0;
}

.emailVFormDescription {
  color: #808080;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  margin-bottom: 16px;
}

.emailVFormDescription > label {
  color: #7250f8;
}

.emailVformCancel {
  cursor: pointer;
  margin-top: 24px;
  color: #7250f8;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 8px;
  background-color: transparent;
  border: none;
}

.emailVFormNotReceived {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin: 24px 0 0 0;
}

.emailVFormNotReceived > label {
  cursor: pointer;
  text-decoration: underline;
  color: black;
}

.resendCountdown{
  cursor: default !important;
  text-decoration: none !important;
  color: #7250f8 !important;
}

.terms-cond-text {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
  margin: 24px 0 0 0;
}
.terms-cond-text > a {
  color: black;
}

.input-error {
  border-color: red;
}
