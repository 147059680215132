.statusConversations__container {
  background-color: white;
  height: calc(100vh - 88px);
  width: 288px;
  display: flex;
  padding-top: 32px;
  flex-direction: column;
  gap: 24px;
  box-shadow: 0px 0px 32px 0px rgba(180, 180, 180, 0.2);
}

.statusConversations__containerTitle {
  padding-left: 16px;
  color: #222;
  font-size: 24px;
  font-weight: 600;
}
.statusConversations__containerStatus {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.statusConversations__itemStatus {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: 11px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.statusConversations__itemStatus > p {
  color: #222;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.16px;
}

.statusConversations__renderContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 6px;
}

.statusConversations__item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.statusConversations__item > img {
  cursor: pointer;
  border-radius: 100%;
}
.statusConversations__item > label {
  color: #222;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
}

.emptySpan {
  color: #bfbfbf;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  transform: translateX(-6px);
}
