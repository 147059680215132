.profileReputationContainer {
  transform: translateY(-149px);
  display: flex;
  flex-direction: column;
  max-width: 1064px;
  width: 100%;
  @media (max-width: 834px) {
    transform: translateY(-52px);
  }
}
.profileReputationTabs {
  display: flex;
  gap: 4px;
}
.profileReputationActiveTab {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  background: white;
  border-radius: 12px 12px 0px 0px;
}
.profileReputationActiveTab > p {
  color: #222;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.profileReputationContent {
  display: flex;
  min-height: 255px;
  flex-direction: column;
  background: white;
  padding: 12px;
  border-radius: 0px 24px 24px 24px;
  align-items: center;
  height: max-content;
  gap: 12px;
}
.profileReputationContentInfo {
  display: flex;
  min-height: 255px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: calc(100% - 64px);
  padding: 32px;
  align-items: center;
  gap: 16px;
  background: #eaedf2;
  border-radius: 12px;
}

.profileReputationContentInfo > p {
  color: #808080;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.14px;
}
