.emailVerificationContainer {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 95vh;
    width: 100%;
    @media (max-width: 1024px) {
      width: 100%;
    }
  }
  
  .emailVerificationFormContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    transform: translateY(-80px);
    @media (max-width: 1024px) {
      width:100%;
      transform: translateY(-80px);
    }
  }
  
  .emailVerificationImageContainer {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 50%;
    @media (max-width: 1024px) {
      display: none;
      width: 100%;
      margin-top: 16px;
    }
  }
  
  .emailVerificationImage {
    width: 110%;
    height: auto;
    @media (min-width: 1280px) {
      width: 100%;
      height: auto;
    }
    @media (min-width: 1440px) {
      width: 80%;
      height: auto;
    }
  }
  