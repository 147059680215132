.footer-container {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  padding: 0px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.footer-info {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  padding: 0px 64px;
  justify-content: space-between;
  align-items: center;
}
.footer-info > img {
  width: 48px;
  height: 48px;
  cursor: pointer;
}
.footer-info > p {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}
.footer-links {
  display: flex;
  padding: 16px 32px;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  border-top: 1px solid #393939;
}
.footer-links > span {
  color: #808080;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}
.copyright-container {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #808080;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
