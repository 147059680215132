.opportunities {
  display: flex;
  flex-direction: column;

  gap: 24px;
}
.opportunitiesHeader {
  padding: 32px 32px 0px 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.opportunitiesHeader > p {
  color: #222;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.opportunitiesHeader > button {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #7250f8;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  outline: none;
}

.opportunitiesHeader > button:hover {
  cursor: pointer;
  background: #4a1ff6;
}
