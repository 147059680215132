.profilePage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 16px 0px 16px;
}
.profileNetRep {
  display: flex;
  max-width: 1064px;
  width: 100%;
  gap: 24px;
  @media (max-width: 834px) {
    flex-direction: column;
  }
}
