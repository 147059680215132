.kanbanEmpty {
  background: rgba(234, 237, 242, 0.5);
  border-radius: 12px;
  height: calc(100vh - 181px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 32px;
}

.emptyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
.emptyImage {
  display: flex;
  align-items: center;
  justify-content: center;
}
.emptyImage > img {
  width: 80%;
  height: auto;
}
.emptyText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.emptyText > p:nth-child(1) {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.emptyText > p:nth-child(2) {
  color: #808080;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.emptyButton > button {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #7250f8;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
}

.emptyButton > button:hover {
  cursor: pointer;
  background: #4a1ff6;
}
