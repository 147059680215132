.overlayCreateOpp {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: auto;
}
.createOppContainer {
  width: 100%;
  max-width: 640px;
  background: white;
  padding: 32px;
  gap: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 32px;
}

.oppContainerCreator {
  @media (max-height: 1084px) {
    transform: translateY(66px);
  }
  @media (max-height: 954px) {
    transform: translateY(140px);
  }
  @media (max-height: 801px) {
    transform: translateY(210px);
  }
  @media (max-height: 660px) {
    transform: translateY(280px);
  }
  @media (max-height: 526px) {
    transform: translateY(350px);
  }
  @media (max-height: 385px) {
    transform: translateY(415px);
  }
}
.createOppForm {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  width: 100%;
}
.createOppForm > p {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}
.createOppFormOppData {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}
.createOppFormUserData {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.titleFormUser {
  width: 100%;
  text-align: center;
  color: #222;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  padding-bottom: 8px;
  border-bottom: #cbd2e0 1px solid;
}

.createOppFormInputContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}
.createOppFormSelectContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}
.createOppFormSelectContainer:last-child {
  width: 100%;
}

.createOppFormSelectContainer > label,
.createOppFormInputContainer > label {
  color: rgba(0, 0, 0, 0.75);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.14px;
}

.createOppFormInputContainer > input {
  display: flex;
  width: calc(100% - 24px);
  padding: 11px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #cbd2e0;
  background: #fff;
  outline: none;
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.16px;
}

.createOppFormInputContainer > input::placeholder {
  color: #cbd2e0;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.16px;
}

.createOppFormTextareaContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  height: 147px;
}
.labelTextareaContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.createOppTextarea {
  width: 100%;
  font-family: Poppins;
  outline: none;
  display: flex;
  padding: 12px;
  box-sizing: border-box;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #cbd2e0;
  background: #fff;
  resize: none;
}
.createOppTextarea::placeholder {
  color: #cbd2e0;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.16px;
}

.labelTextareaContainer > label:first-child {
  color: rgba(0, 0, 0, 0.75);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.14px;
}
.labelTextareaContainer > label:last-child {
  color: #cbd2e0;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.14px;
}

.createOppButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
}
.createOppButtons > button {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: Poppins;
  border-radius: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
}
.buttonSubmit {
  background: #7250f8;
  border: none;
  color: #fff;
}
.buttonSubmit:hover {
  cursor: pointer;
  background: #4a1ff6;
}
.buttonClose {
  cursor: pointer;
  border: 2px solid #000;
  color: #000;
  background: transparent;
}
.buttonClose:hover {
  cursor: pointer;
  background: #cccccc;
}

.formSpamError {
  padding-top: 4px;
  gap: 4px;
  display: flex;
  align-items: center;
  color: #ea2a2a;
  font-size: 12px;
  font-weight: 500;
  line-height: 125%;
  opacity: 0;
  animation: fadeIn 2s forwards;
}
.buttonDisabled {
  color: #808080;
  border: none;
  background: #bfbfbf;
}
