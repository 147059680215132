.messages__container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 8px;
}

.message__container {
  display: flex;
  width: max-content;
  flex-direction: column;
  gap: 16px;
  background: white;
  padding: 16px;
  border-radius: 16px;
}

.message__container > p {
  font-size: 14px;
}

.message__containerOwn {
  align-self: flex-end;
}

.message__containerOther {
  background-color: #cbd2e0;
  align-self: flex-start;
}



.message_statusPending {
  display: flex;
  gap: 8px;
}
.message_statusPending > p {
  color: #808080;
  font-weight: 600;
}

.message_buttonsInit {
  display: flex;
  align-items: center;
  gap: 16px;
}

.button__init {
  padding: 12px 16px;
  border-radius: 8px;
  background: #7250f8;
  border: none;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
}
.button__init:hover {
  cursor: pointer;
  background: #4a1ff6;
}

.button__cancel {
  background: transparent;
  border: none;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
}
.button__cancel:hover {
  cursor: pointer;
  color: #7250f8;
}
