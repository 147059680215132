.authFormLabel {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.14px;
  padding-bottom: 4px;
}

.authFormInputWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #cbd2e0;
  background: #fff;
}
.authFormInput {
  width: 100%;
  border-radius: 8px;
  padding: 11px 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  outline: none;
  border: none;
}
.authFormInput::placeholder {
  color: #cbd2e0;
  font-feature-settings: "calt" off;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.16px;
}

.authFormSpamError {
  padding-top: 4px;
  gap: 4px;
  display: flex;
  align-items: center;
  color: #ea2a2a;
  font-size: 12px;
  font-weight: 500;
  line-height: 125%;
  opacity: 0;
  animation: fadeIn 2s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.authFormButton {
  height: 48px;
  margin-top: 32px;
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  background-color: #7250f8;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.authFormButton:hover {
  cursor: pointer;
  background: #4a1ff6;
}

.authFormEyeIcon {
  cursor: pointer;
  position: absolute;
  transform: translate(-150%, -50%);
}

.inputError {
  border: 1px solid #ea2a2a !important;
}
