.profileCard {
  max-width: 1064px;
  width: 100%;
}
.profileCardBackgroundContainer {
  @media (max-width: 834px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.profileCardBackgroundImg {
  display: flex;
  justify-content: end;
  width: 100%;
  height: 160px;
  border-radius: 32px;
}
.creatorCardBackground {
  background: #4a1ff6;
}
.advertiserCardBackground {
  background: #222;
}
.agencyCardBackground {
  background: #3f3d56;
}
.profileCardBackgroundTag {
  margin: 16px 16px 0 0;
  position: relative;
  height: fit-content;
  padding: 8px 12px;
  gap: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  border-radius: 16px;
}
.creatorTag {
  background: #fff;
  color: #222;
}
.agencyTag {
  background: #7250f8;
  color: #fff;
}
.advertiserTag {
  background: #4a1ff6;
  color: #fff;
}

.creatorProfileAvatarImg {
  width: 158px;
  height: 158px;
  object-fit: cover;
  transform: translate(50%, -50%);
  background: #eaedf2;
  padding: 16px;
  border-radius: 100%;
  @media (max-width: 834px) {
    transform: translate(0, -50%);
  }
}
.agencyProfileAvatarImg,
.advertiserProfileAvatarImg {
  width: 158px;
  height: 158px;
  object-fit: cover;
  transform: translate(50%, -50%);
  background: #eaedf2;
  padding: 16px;
  border-radius: 32px;
  @media (max-width: 834px) {
    transform: translate(0, -50%);
  }
}

.profileCardAvatarCreator {
  position: relative;
}

.profileCardAvatarCreator::before {
  content: "";
  position: absolute;
  top: -47.3px;
  left: 55.5px;
  border-bottom-right-radius: 100%;
  width: 47.5px;
  height: 47.5px;
  box-shadow: 4px 18px 0 1px #eaedf2;
  background: transparent;
}
@media (max-width: 834px) {
  .profileCardAvatarCreator::before {
    content: "";
    position: absolute;
    top: -47.3px;
    left: -40px;
    border-bottom-right-radius: 100%;
    width: 47.5px;
    height: 47.5px;
    box-shadow: 4px 18px 0 1px #eaedf2;
    background: transparent;
  }
}
.profileCardAvatarCreator::after {
  content: "";
  position: absolute;
  top: -47.3px;
  left: 276.5px;
  border-bottom-left-radius: 100%;
  width: 47.5px;
  height: 47.5px;
  box-shadow: -4px 18px 0 1px #eaedf2;
  background: transparent;
}
@media (max-width: 834px) {
  .profileCardAvatarCreator::after {
    content: "";
    position: absolute;
    top: -47.3px;
    left: 182px;
    border-bottom-left-radius: 100%;
    width: 47.5px;
    height: 47.5px;
    box-shadow: -4px 18px 0 1px #eaedf2;
    background: transparent;
  }
}
.profileCardAvatar {
  position: relative;
}

.profileCardAvatar::before {
  content: "";
  position: absolute;
  border-bottom-right-radius: 100%;
  top: -32px;
  left: 64px;
  width: 32px;
  height: 32px;
  box-shadow: 15px 15px 0 15px #eaedf2;
  background: transparent;
}
@media (max-width: 834px) {
  .profileCardAvatar::before {
    content: "";
    position: absolute;
    border-bottom-right-radius: 100%;
    top: -32px;
    left: -32px;
    width: 32px;
    height: 32px;
    box-shadow: 15px 15px 0 15px #eaedf2;
    background: transparent;
  }
}
.profileCardAvatar::after {
  content: "";
  position: absolute;
  top: -32px;
  left: 285.2px;
  border-bottom-left-radius: 100%;
  width: 32px;
  height: 32px;
  box-shadow: -6px 6px 0 6px #eaedf2;
  background: transparent;
}
@media (max-width: 834px) {
  .profileCardAvatar::after {
    content: "";
    position: absolute;
    top: -32px;
    left: 190px;
    border-bottom-left-radius: 100%;
    width: 32px;
    height: 32px;
    box-shadow: -6px 6px 0 6px #eaedf2;
    background: transparent;
  }
}

.profileCardInfoContainer {
  transform: translateY(-197px);
  justify-content: space-between;
  display: flex;
  height: 78px;
  align-items: center;
  @media (max-width: 834px) {
    height: max-content;
    transform: translateY(-100px);
    justify-content: center;
    flex-direction: column;
    gap: 24px;
  }
}
.profileCardInfo {
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  padding-left: 309px;
  gap: 8px;
  @media (max-width: 834px) {
    padding-left: 0px;
    gap: 24px;
  }
}
.profileCardInfoUsername {
  display: flex;
  gap: 16px;
  align-items: center;
  @media (max-width: 834px) {
    flex-direction: column;
    gap: 4px;
  }
}
.profileCardInfoUsername > p {
  color: #222;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.profileCardInfoUsername > label {
  color: #808080;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.profileCardInfoUsername > label > span {
  text-decoration: underline;
}

.profileCardInfoAgency {
  color: #808080;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  @media (max-width: 834px) {
    margin-top: -12px;
  }
}
.profileCardInfoAgency > p > label {
  text-decoration: underline;
}

.profileCardInfoTags {
  display: flex;
  gap: 8px;
  @media (max-width: 834px) {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

.profileCardInfoTags > span {
  display: flex;
  color: #fff;
  padding: 8px 12px;
  border-radius: 100px;
  background: #7250f8;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.profileCardEdit {
  cursor: pointer;
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 2px solid #4a1ff6;
  color: #4a1ff6;
}
.profileCardEdit:hover {
  cursor: pointer;
  background: #dbd2fd;
}
.profileCardEdit > img {
  width: 20px;
  height: 20px;
}
.profileCardEdit > p {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
