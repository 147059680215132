.modalFinishChat {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.modalFinishChat__container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  max-width: 500px;
  padding: 32px;
  border-radius: 32px;
  background: #fff;
  box-shadow: 0px 0px 32px 0px rgba(180, 180, 180, 0.2);
}
.modalFinishChat__title {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 125%;
}
.modalFinishChat__subtitle {
  color: #222;
  text-align: center;

  line-height: 150%;
  letter-spacing: -0.16px;
}

.modalFinishChat__buttons {
  display: flex;
  gap: 16px;
  width: 100%;
}

.modalFinishChat__buttons > button {
  padding: 12px 16px;
  width: 100%;
  font-weight: 600;
  font-family: Poppins;
  font-size: 16px;
  border: none;
}
.modalFinishChat__buttons > button:first-child {
  border-radius: 8px;
  background: #7250f8;
  color: #fff;
}
.modalFinishChat__buttons > button:first-child:hover {
  cursor: pointer;
  background: #4a1ff6;
}
.modalFinishChat__buttons > button:last-child {
  border-radius: 8px;
  border: 2px solid #000;
  color: #000;
  background: none;
}
.modalFinishChat__buttons > button:last-child:hover {
  cursor: pointer;
  background: #cccccc;
}
