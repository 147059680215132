::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #cbd2e0;
}

.kanbanTable {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  height: calc(100vh - 181px);
  overflow-x: auto;
}
.kanbanTableColumn {
  width: 100%;
  min-width: 300px;
  background: rgba(234, 237, 242, 0.5);
  border-radius: 12px;
  overflow: auto;
}
.kanbanTable > div:first-child {
  margin-left: 32px;
}
.kanbanTable > div:last-child {
  margin-right: 32px;
}
.kanbanTableHeader {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-bottom: 1px solid #eaedf2;
}

.statusColorPending {
  width: 6px;
  height: 6px;
  background: #4a1ff6;
  border-radius: 100%;
}
.statusColorNegotiation {
  width: 6px;
  height: 6px;
  background: #f79009;
  border-radius: 100%;
}
.statusColorFinished {
  width: 6px;
  height: 6px;
  background: #12b76a;
  border-radius: 100%;
}
.statusColorCanceled {
  width: 6px;
  height: 6px;
  background: #f04438;
  border-radius: 100%;
}

.kanbanTableStatus {
  color: #3f3d56;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.16px;
}

.countOpp {
  color: #8894ab;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.16px;
}
