.overlayNetwork {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.modalNetwork {
  width: 100%;
  max-width: 640px;
  padding: 32px 32px 6px 32px;
  border-radius: 32px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 32px 0px rgba(180, 180, 180, 0.2);
}

.modalNetworkContainer {
  transform: translateY(-24px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}
.networkImageContainer {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
}
.networkImageContainer > img {
  width: 50%;
  height: auto;
  @media (min-width: 1024px) {
    width: 50%;
  }
}
.networkTextContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
}

.networkTextContainer > p:nth-child(1) {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}
.networkTextContainer > p:nth-child(2) {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: -0.16px;
}
.networkLinkContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  @media (max-width: 534px) {
    flex-direction: column;
  }
}
.networkLink {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 11px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #cbd2e0;
  background: #fff;
  @media (max-width: 534px) {
    width: calc(100% - 26px);
  }
}
.networkLink > input {
  width: 100%;
  color: #bcc3d1;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.16px;
  border: none;
  outline: none;
}

.buttonCopy {
  cursor: pointer;
  width: fit-content;
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #7250f8;
  border: none;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media (max-width: 534px) {
    width: 100%;
  }
}
.buttonCopy:hover{
  cursor: pointer;
  background: #4a1ff6;
}

.closeModal {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transform: translate(8px, -8px);
  z-index: 11;
}
.closeModal > img {
  cursor: pointer;
  width: 24px;
}
