.headerConversations__container {
  display: flex;
  padding: 8px 16px 8px 8px;
  justify-content: space-between;
  align-items: center;
  background: #fff;
}
.headerConversations__user {
  display: flex;
  align-items: center;
  gap: 16px;
}
.headerConversations__user > img {
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: 16px;
}
.headerConversations__user > label {
  color: #222;
  font-size: 20px;
  font-weight: 600;
}
.headerConversations__button {
  display: flex;
  height: 46px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid #000;
  background: none;
}
.headerConversations__button:hover {
  cursor: pointer;
  background: #cccccc;
}
.headerConversations__button > span {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}
.headerConversations__buttonPending {
  display: flex;
  height: 46px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: none;
  background: #bfbfbf;
}
.headerConversations__buttonPending > span {
  color: #808080;
  font-size: 16px;
  font-weight: 600;
}
