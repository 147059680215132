:root {
  --primary-color: #7250f8;
  --secondary-color: black;
  --ternary-color: #fff;
  --disabled-color: #bfbfbf;
  --gradient-color-1: #4a1ff6;
  --error-color: #ea2a2a;
}
body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  min-height: 100%;
  letter-spacing: -0.14px;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

html {
  background-color: #222222;
  height: 100%;
}