.otpContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.otpInput {
  color: #7250f8;
  width: 64px;
  height: 64px;
  text-align: center;
  font-size: 40px;
  background: #eaedf2;
  border: none;
  border-radius: 16px;
  @media (max-width: 500px) {
    width: 50px;
    height: 50px;
    font-size: 32px;
    
  }
}

.otpInput:focus {
  outline: 2px solid #7250f8;
}

.inputError {
  outline: 1.5px solid #ea2a2a !important;
}
