.signUpForm {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 500px;
  @media (max-width: 1024px) {
    width: 100%;
  }
}

.signUpFormCard {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.signUpFormTitle {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 125%;
  margin: 0;
}

.signUpFormNotRegistered {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin: 24px 0 0 0;
}

.signUpFormNotRegistered > a {
  text-decoration: underline;
  color: black;
}

.terms-cond-text {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
  margin: 24px 0 0 0;
}
.terms-cond-text > a {
  color: black;
}
