p {
  margin: 0px;
}

.typeSelectionContainer {
  width: 100%;
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 48px;
  padding: 16px;
  box-sizing: border-box;
}
.typeSelectionTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.typeSelectionTextContainer > p:nth-child(1) {
  color: black;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.typeSelectionTextContainer > p:nth-child(2) {
  color: #808080;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.typeSelectionCardsContainer {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  height: 50vh;
  @media (max-width: 768px) {
    align-items: center;
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}
.typeSelectionCard {
  cursor: pointer;
  display: flex;
  max-width: 302px;
  height: 361px;
  width: 100%;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  background-color: white;
  box-shadow: 0px 0px 32px 0px rgba(180, 180, 180, 0.2);
  transition: box-shadow 0.3s ease;
}

.typeSelectionCard:hover {
  box-shadow: inset 0px 0px 0px 2px #7250f8;
}
.typeSelectionCard img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.typeSelectionCardTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}
.typeSelectionCardTextContainer > p:nth-child(1) {
  color: #222;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 5px 0px;
}
.typeSelectionCardTextContainer > p:nth-child(2) {
  color: #808080;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 3px 0px;
}

@media (max-width: 1024px) {
  .typeSelectionCardTextContainer > p:nth-child(1) {
    font-size: 18px;
  }

  .typeSelectionCardTextContainer > p:nth-child(2) {
    font-size: 11px;
  }
}

@media (max-width: 768px) {
  .typeSelectionCard {
    max-width: 100%;
    width: 90%;
  }
}
@media (max-width: 512px) {
  .typeSelectionCard {
    max-width: 100%;
    width: 85%;
  }
}

.helpSectionAd {
  display: flex;
  gap: 4px;
  align-items: center;
  color: #7250f8;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: default;
}
.helpSection {
  display: flex;
  gap: 4px;
  align-items: center;
  color: #7250f8;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  transition: border-bottom 0.3s ease;
}
.helpSection:hover {
  border-bottom: 1px solid #7250f8;
}

.typeSelectionCard.no-shadow:hover {
  box-shadow: 0px 0px 32px 0px rgba(180, 180, 180, 0.2);
}
.advertiserOpacity {
  opacity: 0.5;
  display: flex;
  max-width: 302px;
  height: 361px;
  width: 100%;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  background-color: white;
  box-shadow: 0px 0px 32px 0px rgba(180, 180, 180, 0.2);
  transition: box-shadow 0.3s ease;
}

@media (max-width: 768px) {
  .advertiserOpacity {
    max-width: 100%;
    width: 90%;
  }
}
@media (max-width: 512px) {
  .advertiserOpacity {
    max-width: 100%;
    width: 85%;
  }
}
