.integrationContainer {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 500px;
  @media (max-width: 1024px) {
    width: 100%;
  }
}

.integrationTitleContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.integrationTitleContainer > label {
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}
.integrationParagraphContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 32px;
}

.integrationParagraphContainer > label {
  color: #808080;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.integrationButton {
  align-items: center;
  background-color: #ea2a2a;
  border-radius: 8px;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  gap: 8px;
  justify-content: center;
  line-height: normal;
  padding: 12px 16px;
  width: max-content;
}
