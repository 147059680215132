input[type="checkbox"] {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  transform: translateY(-4px);
}
input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  border: 2px solid #4a1ff6;
  border-radius: 3px;
  background-color: white;
}
input[type="checkbox"]:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  background-color: #4a1ff6;
}
input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}
.optionContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.imgOption {
  width: 24px;
  height: 24px;
  border-radius: 100%;
}

.customValue {
  margin-right: 4px;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.16px;
  color: #222;
}

.statusColorPENDING {
  width: 6px;
  height: 6px;
  background: #4a1ff6;
  border-radius: 100%;
}
.statusColorNEGOTIATION {
  width: 6px;
  height: 6px;
  background: #f79009;
  border-radius: 100%;
}
.statusColorFINISHED {
  width: 6px;
  height: 6px;
  background: #12b76a;
  border-radius: 100%;
}
.statusColorCANCELED {
  width: 6px;
  height: 6px;
  background: #f04438;
  border-radius: 100%;
}
