.overlayEdit {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  overflow-y: auto;
}

.editProfileContainer {
  overflow-y: auto;
  max-height: calc(100% - 64px);
  width: calc(100% - 64px);
  padding: 32px;
  border-radius: 32px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  @media (min-width: 834px) {
    width: 640px;
  }
}
.mediaWidth {
  @media (max-width: 834px) {
    width: 400px;
  }
  @media (max-width: 512px) {
    width: 350px;
  }
  @media (max-width: 375px) {
    width: calc(100% - 64px);
  }
}
.mediaContainerCreator {
  @media (max-height: 932px) {
    transform: translateY(66px);
  }
  @media (max-height: 800px) {
    transform: translateY(140px);
  }
  @media (max-height: 650px) {
    transform: translateY(210px);
  }
  @media (max-height: 520px) {
    transform: translateY(280px);
  }
  @media (max-height: 370px) {
    transform: translateY(350px);
  }
}
.mediaContainer {
  
  @media (max-height: 700px) {
    transform: translateY(66px);
  }
  @media (max-height: 560px) {
    transform: translateY(140px);
  }
  @media (max-height: 410px) {
    transform: translateY(210px);
  }
}

.editProfileForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
}

.editProfileForm > p {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}

.iconEdit {
  position: absolute;
  border: none;
  width: 48px;
  height: 48px;
  opacity: 0;
  padding: 55px;
  &:hover {
    opacity: 1;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }
}
.radiusImgCreator {
  border-radius: 100%;
}
.radiusImg {
  border-radius: 16px;
}
.editProfileImgContainer {
  width: 100%;
}
.editProfileImg {
  width: 158px;
  height: 158px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 1px solid #cbd2e0;
  background: #eaedf2;
}

.avatarImg {
  width: 158px;
  height: 158px;
  object-fit: cover;
}

.editProfileFormUsername {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
}
.editProfileFormUsername > label {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.14px;
}
.formUsernameInputContainer {
  display: flex;
  padding: 11px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #cbd2e0;
  background: #fff;
}

.formUsernameInputContainer > input {
  width: 100%;
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.16px;
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
}

.editProfileFormDescription {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  height: 147px;
}
.editProfileFormDescriptionTitle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.editProfileFormDescriptionTitle > label {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.14px;
}

.editProfileFormDescriptionTitle > span {
  color: #cbd2e0;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.14px;
}

.editProfileTextarea {
  width: calc(100% - 24px);
  font-family: Poppins;
  outline: none;
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #cbd2e0;
  background: #fff;
  resize: vertical;
}

.editProfileFormTags {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.editProfileFormTagsContainer {
  display: flex;
  flex-direction: column;
}
.editProfileFormTagsContainer > label {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.14px;
  padding-bottom: 4px;
}
.editProfileFormTagsContainer > div:nth-child(2) {
  display: flex;
  flex-wrap: wrap;
  padding: 12px;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #cbd2e0;
  min-height: 32px;
}
.editProfileFormTagsMapping {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.tag {
  cursor: pointer;
  display: flex;
  width: fit-content;
  padding: 8px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  background: #eaedf2;
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.editProfileButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
}
.editProfileButtons > button {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: Poppins;
  border-radius: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
}
.buttonSave {
  background: #7250f8;
  border: none;
  color: #fff;
  cursor: pointer;
}
.buttonSave:hover{
  cursor: pointer;
  background: #4a1ff6;
}
.buttonClose {
  cursor: pointer;
  border: 2px solid #000;
  color: #000;
  background: transparent;
}
.buttonClose:hover {
  cursor: pointer;
  background: #cccccc;
}
.formSpamError {
  padding-top: 4px;
  gap: 4px;
  display: flex;
  align-items: center;
  color: #ea2a2a;
  font-size: 12px;
  font-weight: 500;
  line-height: 125%;
  opacity: 0;
  animation: fadeIn 2s forwards;
}
.buttonDisabled {
  color: #808080;
  border: none;
  background: #bfbfbf;
}
