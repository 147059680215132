.privacyPageContainer {
  background: #222222;
}

.privacyContainer {
  margin: 0px 16px 16px 16px;
  background-color: white;
  display: flex;
  padding: 48px 88px;
  flex-direction: column;
  gap: 16px;
  min-height: calc(100vh - 210px);
  border-radius: 0 0 32px 32px;
}
.privacyTitle {
  color: #222;
  font-family: Poppins;
  font-size: 70px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.privacyTitle > p > span {
  color: #4A1FF6;
}
.privacyContent{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

