.landingPageContainer {
  background: #222222;
}

.landingContainer {
  margin: 0px 16px 16px 16px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  min-height: calc(100vh - 113px);
  border-radius: 0 0 32px 32px;
}

.landingInfoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: 512px) {
    padding: 16px;
  }
}

.imgLeaf {
  width: 8rem;
  position: absolute;
  z-index: -1;
}

.left {
  left: 0;
  top: 30%;
}

.right {
  right: 0;
  top: 20%;
}

.leftBoxContainer {
  padding: 16px;
  width: 630px;
  @media (max-width: 1024px) {
    width: auto;
  }
}

.landingTitle {
  font-size: 80px;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 60px;
  }
}

.primaryColor {
  color: #7250f8;
}

.gradientColor1 {
  color: #4a1ff6;
}

.textContainer {
  display: grid;
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
}

.buttonStart {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  background: #7250f8;
  border: none;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.buttonStart:hover {
  cursor: pointer;
  background: #4a1ff6;
}

.landingImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px;
  @media (max-width: 1024px) {
    display: none;
  }
}

.landingImage {
  width: 90%;
  max-width: 600px;
  height: auto;
}
.buttonsContainer {
  display: flex;
  gap: 16px;
  margin-top: 32px;
  @media (max-width: 512px) {
    flex-direction: column;
  }
}

.buttonHowItWorks {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid #7250f8;
  background: none;
  color: #7250f8;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.buttonHowItWorks:hover {
  cursor: pointer;
  background: #dbd2fd;
}
.buttonHowItWorks > img {
  width: 24px;
}
