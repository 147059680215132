.termsPageContainer {
    background: #222222;
  }
  
  .termsContainer {
    margin: 0px 16px 16px 16px;
    background-color: white;
    display: flex;
    padding: 48px 88px;
    gap: 16px;
    flex-direction: column;
    min-height: calc(100vh - 210px);
    border-radius: 0 0 32px 32px;
  }
  .termsTitle {
    color: #222;
    font-family: Poppins;
    font-size: 70px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .termsTitle > p > span {
    color: #4A1FF6;
  }
  .termsContent{
      display: flex;
      flex-direction: column;
      gap: 16px;
  }
