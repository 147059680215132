.aboutPageContainer {
  background: #222222;
}

.aboutContainer {
  margin: 0px 16px 16px 16px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 100px 88px;
  min-height: calc(100vh - 318px);
  border-radius: 0 0 32px 32px;
  gap: 16px;
}

.aboutTitle {
  color: #222;
  font-family: Poppins;
  font-size: 70px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.aboutTitle > span:nth-child(1) {
  color: #7250f8;
}
.aboutTitle > span:nth-child(2) {
  color: #4a1ff6;
}
.aboutTitle > span:nth-child(3) {
  color: #4a1ff6;
}

.aboutDescription {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.aboutDescription > p {
  color: #222;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
