.profileAboutContainer {
  transform: translateY(-173px);
  max-width: 1064px;
  width: 100%;
  @media (max-width: 834px) {
    transform: translateY(-76px);
  }
}
.profileAboutContainer > div {
  background-color: white;
  border-radius: 24px;
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.profileAboutContainer > div > p:nth-child(1) {
  color: #393939;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.profileAboutContainer > div > p:nth-child(2) {
  color: #222;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.14px;
  white-space: break-spaces;
}

.profileAboutEmptyContainer {
  transform: translateY(-173px);
  max-width: 1064px;
  width: 100%;
  @media (max-width: 834px) {
    transform: translateY(-76px);
  }
}
.profileAboutEmpty {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  border: 16px solid white;
  border-radius: 24px;
  padding: 16px;
}

.profileAboutEmpty > p {
  color: #808080;
  text-align: center;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: -0.12px;
}
.profileAboutEmpty > button {
  outline: none;
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #7250f8;
  color: #fff;
  border: none;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
}

.profileAboutEmpty > button:hover{
  background: #4a1ff6;
  cursor: pointer;
}
